<template>
  <BaseList
    :list-query="groups"
    :name-map="nameMap"
    route="group"
    locale-section="pages.groups"
  ></BaseList>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "GroupsList",
  components: {
    BaseList: () => import("@/components/BaseList")
  },
  data() {
    return {
      groups: gql`
        query groups {
          list: groups {
            id: id
            type
            objects {
              standardSigna {
                signum1
                signum2
              }
            }
            inscriptions {
              signumInscriptions {
                canonical
                signum {
                  signum1 {
                    signum1
                  }
                  signum2
                }
              }
            }
          }
        }
      `
    };
  },
  methods: {
    nameMap(element) {
      return {
        name:
          this.$t("pages.groups." + element.type) +
          ": " +
          (element.type == "carver"
            ? element.inscriptions.map(inscription => {
                return inscription.signumInscriptions
                  .filter(si => si.canonical)
                  .map(
                    si => si.signum.signum1.signum1 + " " + si.signum.signum2
                  );
              })
            : element.objects.map(object => {
                return object.standardSigna.map(signum => {
                  return signum.signum1 + " " + signum.signum2;
                });
              })
          ).join(" / "),
        ...element
      };
    }
  }
};
</script>
